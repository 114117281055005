import React, { FC } from "react";
import Layout from "../components/layout";
// import Img, { FixedObject } from "gatsby-image";
import { H1 } from "../components/ui/typography";
import { ButtonLink } from "../components/ui/form/button";
import { graphql, useStaticQuery } from "gatsby";
import { Query } from "../generated/graphql";

// type imageTypes = {
//   name: string;
//   relativePath: string;
// };

const NotFoundPage: FC = () => {
  const image = useStaticQuery<Query>(graphql`
    query {
      allFile(filter: { name: { eq: "404" } }) {
        edges {
          node {
            name
            relativePath
          }
        }
      }
    }
  `);

  console.log(image);

  return (
    <Layout pageTitle={"notFound"}>
      {/*<Img*/}
      {/*  fixed={{ src: image.allFile.edges[0].node.relativePath } as FixedObject}*/}
      {/*  alt={image.allFile.edges[0].node.name}*/}
      {/*/>*/}
      <H1>Oops! Die gesuchte Seite ist verloren gegangen...</H1>
      <ButtonLink to="/">Go back home</ButtonLink>
    </Layout>
  );
};

export default NotFoundPage;
